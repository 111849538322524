#portfolio {
  padding-top: 2.5rem;
  height: 900px;
}

.container.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  padding-bottom: 4rem;
  width: 70%;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem 1.3rem 0.4rem;
  border-radius: 2rem;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item:hover .portfolio__item-cta {
  opacity: 85%;
  transform: translateY(0);
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
  width: 100%;
  height: 275px;
}

.portfolio__item-title {
  margin: 1rem 0;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  position: absolute;
  top: 0;
  left: 0;
  opacity: 0%;
  background: #000;
  transition: all 1s ease;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  padding: 2rem 2.5rem;
  transform: translateY(100%);
}

.portfolio__item-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.portfolio__item-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  background: var(--color-primary);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
  #portfolio {
    height: 100vh;
  }

  .container.portfolio__container {
    width: 70%;
  }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 768px) {
  .container.portfolio__container {
    width: 70%;
  }
}